<template>
  <div class="members-directory">
    <div class="panel-inr-box">
      <div>
        <div class="d-flex flex-column fill-height" v-if="!isPartScreen">
          <div class="box-top-wrap d-flex align-center justify-space-between">
            <div class="item-row">
              <button @click="$router.go(-1)" class="text-capitalize
              btn customBtn pattern-btn
              white--text" elevation="0">
                <span> {{ $t("invoice.back") }}</span>
              </button>
              <h3 class="box-title green-text">
                <span>{{ $t("invoice.createForm.title") }}</span>
              </h3>
            </div>
          </div>
          <div class="custom-card vehicle-car">
            <div class="custom-box pd-0">
              <template>
                <div class="custom-steps-wrap" id="scrolling_div">
                  <v-stepper v-model="e1" v-if="!isStepHidden" alt-labels style="box-shadow: none;">
                    <v-stepper-header>
                      <v-stepper-step :complete="e1 > 1" step="1">{{ $t("invoice.createForm.step1") }}</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="e1 > 2" step="2">{{ $t("invoice.createForm.step2") }}</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="e1 > 3" step="3">{{ $t("invoice.createForm.step3") }}</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="e1 > 4" step="4">{{ $t("invoice.createForm.step4") }}</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="5">{{ $t("invoice.createForm.step5") }}</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items class="rd-stepper-content">
                      <!-- {{ vErrors.all() }} -->
                      <v-stepper-content step="1">
                        <!-- <div class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"> -->
                        <div class="interchange-box">
                          <form data-vv-scope="form1">
                            <v-row class="mt-1">
                              <v-col cols="12" md="12" class="pt-0">
                                <p class="not-carry alert-danger mb-0"
                                  v-html="$t('invoice.createForm.customPartNotes')"></p>
                              </v-col>
                            </v-row>
                            <v-row class="filterField" v-if="$vuetify.breakpoint.width > 767">
                              <v-col cols="8" md="8">
                                <v-row>
                                  <v-col cols="12" md="12">
                                    <label class="form-label">{{ $t('invoice.createForm.carLineYear') }}</label>
                                    <v-autocomplete height="45" v-model="beginYear" :items="yearList"
                                      item-text="CarlineYear" item-value="CarlineYear" @change="onChangeYear('search')"
                                      clearable solo dense hide-details
                                      :placeholder="$t('invoice.createForm.carLineYear')" :loading="modelYearLoading">
                                    </v-autocomplete>
                                    <span class="note-text">{{ $t('interchange.searchNote') }}</span>
                                  </v-col>
                                  <v-col cols="12" md="6" class="pt-0">
                                    <label class="form-label">{{ $t('interchange.hollanderMake') }}</label>
                                    <v-autocomplete :disabled="!beginYear" height="45" v-model="make" :items="makeList"
                                      item-text="label" item-value="MfrName" return-object
                                      @change="fetchModel('search')" clearable solo dense hide-details
                                      :placeholder="$t('interchange.hollanderMake')" :loading="searchModelLoading">
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="6" class="pt-0">
                                    <label class="form-label">{{ $t('interchange.hollanderModel') }}</label>
                                    <v-autocomplete :disabled="!make" height="45" v-model="model" :items="modelList"
                                      item-text="ModelNm" item-value="ModelNm" return-object @change="fetchPartType"
                                      clearable solo dense hide-details :placeholder="$t('interchange.hollanderModel')"
                                      :loading="searchModelLoading">
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="12" class="pt-0">
                                    <label class="form-label">{{ $t('invoice.createForm.partType') }}</label>
                                    <v-autocomplete :disabled="!model" height="50" v-model="partType" :items="pType"
                                      item-text="Description" item-value="id" @change="fetchPartsList" return-object
                                      clearable solo dense hide-details :placeholder="$t('invoice.createForm.partType')"
                                      :loading="partTypeLoading">
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="4" md="4">
                                <div class="rd-add-custom-part">
                                  <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text"
                                    @click="resetCustomPartsForm(), addCustomPartDialog = true">
                                    <span> {{
                                      $t('invoice.createForm.addCustomPart') }} </span>
                                  </v-btn>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row class="filterField" v-else="$vuetify.breakpoint.width < 767">
                              <v-col cols="12">
                                <div class="rd-add-custom-part">
                                  <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text"
                                    @click="resetCustomPartsForm(), addCustomPartDialog = true">
                                    <span> {{
                                      $t('invoice.createForm.addCustomPart') }} </span>
                                  </v-btn>
                                </div>
                              </v-col>
                              <v-col cols="12">
                                <v-row>
                                  <v-col cols="12" md="12">
                                    <label class="form-label">{{ $t('invoice.createForm.carLineYear') }}</label>
                                    <v-autocomplete height="45" v-model="beginYear" :items="yearList"
                                      item-text="CarlineYear" item-value="CarlineYear" @change="onChangeYear('search')"
                                      clearable solo dense hide-details
                                      :placeholder="$t('invoice.createForm.carLineYear')" :loading="modelYearLoading">
                                    </v-autocomplete>
                                    <span class="note-text">{{ $t('interchange.searchNote') }}</span>
                                  </v-col>
                                  <v-col cols="12" md="6" class="pt-0">
                                    <label class="form-label">{{ $t('interchange.hollanderMake') }}</label>
                                    <v-autocomplete :disabled="!beginYear" height="45" v-model="make" :items="makeList"
                                      item-text="label" item-value="MfrName" return-object
                                      @change="fetchModel('search')" clearable solo dense hide-details
                                      :placeholder="$t('interchange.hollanderMake')" :loading="searchModelLoading">
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="6" class="pt-0">
                                    <label class="form-label">{{ $t('interchange.hollanderModel') }}</label>
                                    <v-autocomplete :disabled="!make" height="45" v-model="model" :items="modelList"
                                      item-text="ModelNm" item-value="ModelNm" return-object @change="fetchPartType"
                                      clearable solo dense hide-details :placeholder="$t('interchange.hollanderModel')"
                                      :loading="searchModelLoading">
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="12" class="pt-0">
                                    <label class="form-label">{{ $t('invoice.createForm.partType') }}</label>
                                    <v-autocomplete :disabled="!model" height="50" v-model="partType" :items="pType"
                                      item-text="Description" item-value="id" @change="fetchPartsList" return-object
                                      clearable solo dense hide-details :placeholder="$t('invoice.createForm.partType')"
                                      :loading="partTypeLoading">
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="12" class="text-right">
                                <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text mt-2 mb-2" @click.native="isPartScreen = true"
                                  :disabled="!invoiceForm.items.length">
                                  <span>{{ $t('invoice.next') }} <v-icon size="19"
                                      class="white--text ml-1">mdi-arrow-right</v-icon></span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </form>
                        </div>
                        <!-- </div> -->
                      </v-stepper-content>
                      <v-stepper-content step="2">
                        <!-- <div class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"> -->
                        <div class="interchange-box">
                          <div class="pa-0 flex-grow-1 filterField pt-3">
                            <form data-vv-scope="form2">
                              <v-row>
                                <v-col>
                                  <label class="form-label"> {{ $t('invoice.createForm.workOrder') }}</label>
                                  <v-radio-group v-model="invoiceForm.work_order" row class="custom-pdm"
                                    hide-details="auto" :error-messages="vErrors.collect('invoiceForm.work_order')"
                                    v-validate="'required'" data-vv-name="invoiceForm.work_order" required
                                    data-vv-scope="form2" :data-vv-as="$t('invoice.createForm.isWorkOrder')">
                                    <div class="interchange-radio">
                                      <v-radio :label="$t('invoice.yes')" value="2"></v-radio>
                                      <v-radio :label="$t('invoice.no')" value="1"></v-radio>
                                    </div>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                              <div v-if="invoiceForm.work_order != null">
                                <v-row>
                                  <v-col cols="12">
                                    <v-select height="45" :items="typeOption" item-text="value" item-value="key"
                                      v-model="invoiceForm.customer_type" :label="$t('invoice.createForm.customerType')"
                                      solo dense hide-details="auto"
                                      :error-messages="vErrors.collect('invoiceForm.customer_type')"
                                      v-validate="'required'" data-vv-name="invoiceForm.customer_type" required
                                      data-vv-scope="form2"
                                      :data-vv-as="$t('invoice.createForm.customerType')"></v-select>
                                  </v-col>
                                </v-row>
                                <div
                                  :style="{ display: (invoiceForm.customer_type == 'body_shop' || invoiceForm.customer_type == 'dismantler') ? 'block' : 'none' }">
                                  <v-row>
                                    <v-col cols="6">
                                      <v-combobox v-model="invoiceForm.customer.business_name" solo dense hide-no-data
                                        hide-details :items="customerSuggestions" item-text="lable"
                                        item-value="business_name" :search-input.sync="searchBusiness"
                                        @change="(event) => onSelectBusiness(event)"
                                        :placeholder="$t('invoice.createForm.businessName')" append-icon=""
                                        :error-messages="vErrors.collect('invoiceForm.customer.business_name')"
                                        v-validate="{ required: (invoiceForm.customer_type != 'customer') }"
                                        data-vv-name="invoiceForm.customer.business_name" data-vv-scope="form2"
                                        :data-vv-as="$t('invoice.createForm.businessName')"></v-combobox>
                                      <div class="v-text-field__details" style="margin-top: 4px;"
                                        v-if="vErrors.has('invoiceForm.customer.business_name')">
                                        <div class="v-messages theme--light error--text" role="alert">
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">{{
                                              vErrors.collect('invoiceForm.customer.business_name')[0] }}</div>
                                          </div>
                                        </div>
                                      </div>
                                      <span class="note-text" v-html="$t('invoice.createForm.searchNote')"></span>
                                    </v-col>
                                    <v-col cols="6">
                                      <vue-tel-input class="mr-3" style="height: 50px; width: 100%" elevation="0" dense
                                        v-model="invoiceForm.customer.business_phone" validCharactersOnly
                                        v-on:country-changed="businessPhoneCountryChanged" :inputOptions="{
                                          maxlength: 14,
                                          minlength: 10,
                                          placeholder: $t('invoice.createForm.businessPhone'),
                                        }" :dynamicPlaceholder="true" :disabledFormatting="false" defaultCountry="US"
                                        :onlyCountries="['US', 'MX', 'CA']"
                                        :error-messages="vErrors.collect('invoiceForm.customer.business_phone')"
                                        data-vv-name="invoiceForm.customer.business_phone"
                                        v-validate="{ required: (invoiceForm.customer_type != 'customer'), regex: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/ }"
                                        validation-key="invoiceForm.customer.business_phone" required
                                        data-vv-scope="form2" :data-vv-as="$t('invoice.createForm.businessPhone')" />
                                      <div v-if="vErrors.has('invoiceForm.customer.business_phone')" style="color: red">
                                        {{
                                          vErrors.collect('invoiceForm.customer.business_phone')[0] }}</div>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    :style="{ display: (invoiceForm.customer_type == 'body_shop' || invoiceForm.customer_type == 'dismantler') ? 'none' : 'block' }">
                                    <v-col cols="6">
                                      <v-text-field height="45" v-model="invoiceForm.customer.bd_customer_name"
                                        :placeholder="$t('invoice.createForm.customerName')" outlined
                                        hide-details="auto"
                                        :error-messages="vErrors.collect('invoiceForm.customer.bd_customer_name')"
                                        data-vv-name="invoiceForm.customer.bd_customer_name"
                                        validation-key="invoiceForm.customer.bd_customer_name" required dense solo
                                        data-vv-scope="form2" :data-vv-as="$t('invoice.createForm.customerName')">
                                        <!-- v-validate="{ required: (invoiceForm.customer_type != 'customer') }" -->
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <vue-tel-input class="mr-3" elevation="0" dense solo
                                        v-model="invoiceForm.customer.customer_phone" validCharactersOnly
                                        v-on:country-changed="customerPhoneCountryChanged" :inputOptions="{
                                          maxlength: 14,
                                          minlength: 10,
                                          placeholder: $t('invoice.createForm.customerPhone'),
                                        }" :dynamicPlaceholder="true" defaultCountry="US"
                                        :onlyCountries="['US', 'MX', 'CA']"
                                        :error-messages="vErrors.collect('invoiceForm.customer.customer_phone')"
                                        data-vv-name="invoiceForm.customer.customer_phone"
                                        validation-key="invoiceForm.customer.customer_phone" required
                                        data-vv-scope="form2" :data-vv-as="$t('invoice.createForm.customerPhone')" />
                                      <!-- v-validate="{ required: (invoiceForm.customer_type != 'customer'), regex: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/ }"  -->
                                      <div v-if="vErrors.has('invoiceForm.customer.customer_phone')" style="color: red">
                                        {{
                                          vErrors.collect('invoiceForm.customer.customer_phone')[0] }}</div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" class="pt-0">
                                      <GooglePlace class="customer-address" id="customer_address"
                                        @onAddressSelect="onCustomerAddressSelect"
                                        :address="invoiceForm.customer.address"
                                        :label="$t('invoice.createForm.address')" dense solo
                                        :validationMsg="$t('invoice.createForm.address')" :isRequired="true">
                                      </GooglePlace>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="4" class="pt-0">
                                      <v-text-field v-model="invoiceForm.customer.city"
                                        :placeholder="$t('invoice.createForm.city')" dense solo hide-details="auto">
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" class="pt-0">
                                      <v-text-field v-model="invoiceForm.customer.state"
                                        :placeholder="$t('invoice.createForm.state')" dense solo hide-details="auto">
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" class="pt-0">
                                      <v-text-field v-model="invoiceForm.customer.zip"
                                        :placeholder="$t('invoice.createForm.zip')" dense solo hide-details="auto">
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div :style="{ display: (invoiceForm.customer_type == 'customer') ? 'block' : 'none' }"
                                  class="">
                                  <v-row>
                                    <v-col cols="6">
                                      <v-combobox v-model="invoiceForm.customer.customer_name" solo dense hide-no-data
                                        hide-details :items="customerSuggestions" item-text="lable"
                                        item-value="customer_name" :search-input.sync="searchCustomers"
                                        @change="(event) => onSelectCustomers(event)"
                                        :placeholder="$t('invoice.createForm.customerName')" append-icon=""
                                        :error-messages="vErrors.collect('invoiceForm.customer.customer_name')"
                                        v-validate="{ required: (invoiceForm.customer_type == 'customer') }"
                                        data-vv-name="invoiceForm.customer.customer_name" required data-vv-scope="form2"
                                        :data-vv-as="$t('invoice.createForm.customerName')"></v-combobox>
                                      <span class="note-text" v-html="$t('invoice.createForm.searchNote')"></span>
                                    </v-col>
                                    <v-col cols="6">
                                      <vue-tel-input class="mr-3" style="height: 50px; width: 100%" elevation="0" dense
                                        v-model="invoiceForm.customer.customer_phone" validCharactersOnly
                                        v-on:country-changed="customerPhoneCountryChanged" :inputOptions="{
                                          maxlength: 14,
                                          minlength: 10,
                                          placeholder: $t('invoice.createForm.customerPhone'),
                                        }" :dynamicPlaceholder="true" defaultCountry="US"
                                        :onlyCountries="['US', 'MX', 'CA']"
                                        :error-messages="vErrors.collect('invoiceForm.customer.customer_phone')"
                                        data-vv-name="invoiceForm.customer.customer_phone"
                                        v-validate="{ required: (invoiceForm.customer_type == 'customer'), regex: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/ }"
                                        validation-key="invoiceForm.customer.customer_phone" required
                                        data-vv-scope="form2" :data-vv-as="$t('invoice.createForm.customerPhone')" />
                                      <div v-if="vErrors.has('invoiceForm.customer.customer_phone')" style="color: red">
                                        {{
                                          vErrors.collect('invoiceForm.customer.customer_phone')[0] }}</div>
                                    </v-col>
                                  </v-row>
                                </div>
                                <v-row>
                                  <v-col cols="6">
                                    <label class="form-label">{{ $t('invoice.createForm.taxResaleCertificateNumberText')
                                      }}</label>
                                    <v-radio-group v-model="invoiceForm.customer.is_tax_resale_number" row
                                      class="custom-pdm" hide-details="auto" required
                                      :error-messages="vErrors.collect('invoiceForm.customer.is_tax_resale_number')"
                                      v-validate="'required'" data-vv-name="invoiceForm.customer.is_tax_resale_number"
                                      data-vv-scope="form2"
                                      :data-vv-as="$t('invoice.createForm.taxResaleCertificateNumberText')">
                                      <div class="interchange-radio">
                                        <v-radio :label="$t('invoice.yes')" :value="true"></v-radio>
                                        <v-radio :label="$t('invoice.no')" :value="false"></v-radio>
                                      </div>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12" v-if="invoiceForm.customer.is_tax_resale_number == true"
                                    class="pt-0">
                                    <v-text-field v-model="invoiceForm.customer.tax_resale_number"
                                      :placeholder="$t('invoice.createForm.taxResaleCertificateNumberText')" solo dense
                                      hide-details="auto">
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                            </form>
                          </div>
                          <div class="d-flex mt-5 mb-3">
                            <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text" @click.native="isPartScreen = true, e1 = 1">
                              <v-icon size="19" class="white--text mr-1">mdi-arrow-left</v-icon> {{
                                $t('invoice.previous')
                              }}
                            </v-btn>
                            <v-spacer />
                            <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text" @click.native="stepContinue('form2')"
                              :disabled="step2ButtonValidation()">
                              {{ $t('invoice.next') }} <v-icon size="19"
                                class="white--text ml-1">mdi-arrow-right</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <!-- </div> -->
                      </v-stepper-content>
                      <v-stepper-content step="3">
                        <!-- <div class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"> -->
                        <div class="interchange-box">
                          <form data-vv-scope="form3" class="filterField">
                            <v-row class="mt-1">
                              <v-col cols="6">
                                <label class="form-label">{{ $t('invoice.createForm.shippingInformation') }}</label>
                                <v-radio-group v-model="invoiceForm.customer.ship_is_shipping_required" row
                                  class="custom-pdm mt-2" hide-details="auto">
                                  <div class="interchange-radio">
                                    <v-radio :label="$t('invoice.yes')" :value="true" class="pb-2"></v-radio>
                                    <v-radio :label="$t('invoice.no')" :value="false" class="pb-2"></v-radio>
                                  </div>
                                </v-radio-group>
                              </v-col>
                              <v-col cols="6" v-if="invoiceForm.customer.ship_is_shipping_required">
                                <label class="form-label"> {{ $t('invoice.createForm.userAddressInformation') }}</label>
                                <v-radio-group v-model="useCustomerAddress" row class="custom-pdm mt-2"
                                  hide-details="auto" :error-messages="vErrors.collect('useCustomerAddress')"
                                  v-validate="'required'" data-vv-name="useCustomerAddress" required
                                  data-vv-scope="form3" :data-vv-as="$t('invoice.createForm.userAddressInformation')">
                                  <div class="interchange-radio">
                                    <v-radio :label="$t('invoice.yes')" value="yes" class="pb-2"></v-radio>
                                    <v-radio :label="$t('invoice.no')" value="no" class="pb-2"></v-radio>
                                  </div>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                            <div v-if="invoiceForm.customer.ship_is_shipping_required">
                              <v-row>
                                <v-col cols="6">
                                  <v-text-field v-model="invoiceForm.customer.ship_customer_name"
                                    :placeholder="$t('invoice.createForm.name')" hide-details="auto"
                                    :error-messages="vErrors.collect('invoiceForm.customer.ship_customer_name')"
                                    data-vv-name="invoiceForm.customer.ship_customer_name" v-validate="'required'"
                                    validation-key="ship_customer_name" required data-vv-scope="form3"
                                    :data-vv-as="$t('invoice.createForm.name')" v-uppercase solo dense>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <vue-tel-input style="height: 50px; width: 100%" elevation="0" outlined
                                    v-model="invoiceForm.customer.ship_customer_phone" validCharactersOnly
                                    v-on:country-changed="shippingPhoneCountryChanged" :inputOptions="{
                                      maxlength: 14,
                                      minlength: 10,
                                      placeholder: $t('invoice.createForm.phone'),
                                    }" :dynamicPlaceholder="true" defaultCountry="US"
                                    :onlyCountries="['US', 'MX', 'CA']" />
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="8" class="pt-0">
                                  <GooglePlace class="customer-address" id="shipping_address"
                                    @onAddressSelect="onShippingAddressSelect"
                                    :address="invoiceForm.customer.ship_address"
                                    :placeholder="$t('invoice.createForm.address')" dense solo
                                    :validationMsg="$t('invoice.createForm.address')" :isRequired="true">
                                  </GooglePlace>
                                  <div v-if="vErrors.has('invoiceForm.customer.ship_address')" style="color: red">{{
                                    vErrors.collect('invoiceForm.customer.ship_address')[0] }}</div>
                                </v-col>
                                <v-col cols="12" sm="4" class="pt-0">
                                  <v-text-field v-model="invoiceForm.customer.ship_city"
                                    :placeholder="$t('invoice.createForm.city')" solo dense hide-details="auto"
                                    :error-messages="vErrors.collect('invoiceForm.customer.ship_city')"
                                    data-vv-name="invoiceForm.customer.ship_city"
                                    v-validate="{ required: (invoiceForm.customer.ship_is_shipping_required) }"
                                    validation-key="invoiceForm.customer.ship_city" required data-vv-scope="form3"
                                    :data-vv-as="$t('invoice.createForm.city')">
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="3" class="pt-0">
                                  <v-text-field v-model="invoiceForm.customer.ship_state"
                                    :placeholder="$t('invoice.createForm.state')" solo dense hide-details="auto"
                                    :error-messages="vErrors.collect('invoiceForm.customer.ship_state')"
                                    data-vv-name="invoiceForm.customer.ship_state"
                                    v-validate="{ required: (invoiceForm.customer.ship_is_shipping_required) }"
                                    validation-key="invoiceForm.customer.ship_state" required data-vv-scope="form3"
                                    :data-vv-as="$t('invoice.createForm.state')">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" class="pt-0">
                                  <v-text-field v-model="invoiceForm.customer.ship_zip"
                                    :placeholder="$t('invoice.createForm.zip')" solo dense hide-details="auto"
                                    :error-messages="vErrors.collect('invoiceForm.customer.ship_zip')"
                                    data-vv-name="invoiceForm.customer.ship_zip"
                                    v-validate="{ required: (invoiceForm.customer.ship_is_shipping_required) }"
                                    validation-key="invoiceForm.customer.ship_zip" required data-vv-scope="form3"
                                    :data-vv-as="$t('invoice.createForm.zip')">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0">
                                  <v-text-field v-model="invoiceForm.customer.ship_note"
                                    :placeholder="$t('invoice.createForm.shipNote')" solo dense hide-details="auto"
                                    maxlength="150">
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="d-flex mt-5 mb-3">
                              <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text" @click.native="goBack('form2')">
                                <v-icon size="19" class="white--text mr-1">mdi-arrow-left</v-icon> {{
                                  $t('invoice.previous')
                                }}
                              </v-btn>
                              <v-spacer />
                              <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text" @click.native="stepContinue('form3')"
                                :disabled="step3ButtonValidation()">
                                {{ $t('invoice.next') }} <v-icon size="19"
                                  class="white--text ml-1">mdi-arrow-right</v-icon>
                              </v-btn>
                            </div>
                          </form>
                        </div>
                        <!-- </div> -->
                      </v-stepper-content>
                      <v-stepper-content step="4">
                        <!-- <div class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"> -->
                        <!-- v-bind:style="(invoiceForm.payment.payment_term == 'billing_payments' && invoiceForm.payment.down_payment) ? 'height: 100%;' : ''" -->
                        <div class="interchange-box">
                          <form data-vv-scope="form4" class="filterField">
                            <v-row class="mt-1 mb-1">
                              <v-col cols="6">
                                <v-row v-if="invoiceForm.work_order == 1">
                                  <v-col cols="12">
                                    <v-select :items="paymentTermOption" item-text="value" item-value="key"
                                      v-model="invoiceForm.payment.payment_term"
                                      :placeholder="$t('invoice.createForm.paymentTerm')" dense solo hide-details="auto"
                                      :error-messages="vErrors.collect('invoiceForm.payment.payment_term')"
                                      v-validate="'required'" data-vv-name="invoiceForm.payment.payment_term" required
                                      data-vv-scope="form4"
                                      :data-vv-as="$t('invoice.createForm.paymentTerm')"></v-select>
                                  </v-col>
                                  <v-col cols="12" class="pt-0"
                                    v-if="invoiceForm.payment.payment_term == 'billing_payments'">
                                    <v-select :items="paymentDueDaysOption" item-text="value" item-value="key"
                                      v-model="invoiceForm.payment.payment_due_days"
                                      :placeholder="$t('invoice.createForm.paymentDueDays')" dense solo
                                      hide-details="auto"
                                      :error-messages="vErrors.collect('invoiceForm.payment.payment_due_days')"
                                      v-validate="'required'" data-vv-name="invoiceForm.payment.payment_due_days"
                                      required data-vv-scope="form4"
                                      :data-vv-as="$t('invoice.createForm.paymentDueDays')"></v-select>
                                  </v-col>
                                </v-row>
                                <v-row v-if="invoiceForm.payment.payment_term">
                                  <v-col cols="12" v-if="invoiceForm.payment.payment_term == 'billing_payments'">
                                    <v-row class="text-left">
                                      <v-col cols="12" class="pt-0">
                                        <label class="form-label"> {{ $t('invoice.createForm.downPayment') }}</label>
                                        <v-radio-group v-model="invoiceForm.payment.down_payment" row class="custom-pdm"
                                          hide-details="auto" :error-messages="vErrors.collect('useCustomerAddress')"
                                          v-validate="'required'" data-vv-name="useCustomerAddress" required
                                          data-vv-scope="form4" :data-vv-as="$t('invoice.createForm.downPayment')">
                                          <div class="interchange-radio">
                                            <v-radio :label="$t('invoice.yes')" :value="true" class="pb-2"></v-radio>
                                            <v-radio :label="$t('invoice.no')" :value="false" class="pb-2"></v-radio>
                                          </div>
                                        </v-radio-group>
                                      </v-col>
                                      <v-col :cols="12" class="pt-0"
                                        v-if="invoiceForm.payment.payment_term == 'billing_payments' && invoiceForm.payment.down_payment == true">
                                        <v-text-field type="number" v-model="invoiceForm.payment.deposit_amount"
                                          :placeholder="$t('invoice.createForm.depositAmount')" solo dense
                                          hide-details="auto" hide-spin-buttons
                                          :error-messages="vErrors.collect('invoiceForm.payment.deposit_amount')"
                                          v-validate="{ required: invoiceForm.payment.down_payment, max_value: getTotalAmount() }"
                                          data-vv-name="invoiceForm.payment.deposit_amount" data-vv-scope="form4"
                                          :data-vv-as="$t('invoice.createForm.depositAmount')">
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pt-0"
                                    v-if="invoiceForm.payment.payment_term == 'full_charge' || invoiceForm.payment.down_payment == true">
                                    <v-select :items="paymentMethodOption" item-text="value" item-value="key"
                                      v-model="invoiceForm.payment.payment_method"
                                      :placeholder="$t('invoice.createForm.paymentMethod')" dense solo
                                      hide-details="auto"
                                      :error-messages="vErrors.collect('invoiceForm.payment.payment_method')"
                                      v-validate="'required'" data-vv-name="invoiceForm.payment.payment_method" required
                                      data-vv-scope="form4"
                                      :data-vv-as="$t('invoice.createForm.paymentMethod')"></v-select>
                                  </v-col>
                                  <!-- </v-row>
                                <v-row> -->
                                  <v-col cols="12" class="pt-0"
                                    v-if="invoiceForm.payment.payment_method == 'credit_card_number'">
                                    <v-text-field v-model="invoiceForm.payment.credit_card_number"
                                      :placeholder="$t('invoice.createForm.last4DigitOfCreditCard')" solo dense
                                      hide-details="auto" maxlength="4"
                                      :error-messages="vErrors.collect('invoiceForm.payment.credit_card_number')"
                                      v-validate="{ required: (invoiceForm.payment.payment_method == 'credit_card_number'), max: 4, min: 4 }"
                                      data-vv-name="invoiceForm.payment.credit_card_number" data-vv-scope="form4"
                                      :data-vv-as="$t('invoice.createForm.last4DigitOfCreditCard')">
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="6" v-if="invoiceForm.payment.payment_method == 'cheque'" class="pt-0">
                                    <v-text-field v-model="invoiceForm.payment.bank_name"
                                      :placeholder="$t('invoice.createForm.bankName')" solo dense hide-details="auto">
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="6" v-if="invoiceForm.payment.payment_method == 'cheque'" class="pt-0">
                                    <v-text-field v-model="invoiceForm.payment.cheque_number"
                                      :placeholder="$t('invoice.createForm.chequeNumber')" solo dense
                                      hide-details="auto">
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pt-0">
                                    <v-text-field v-model="invoiceForm.payment.sales_person_name"
                                      :placeholder="$t('invoice.createForm.salesPersonName')" solo dense
                                      hide-details="auto">
                                    </v-text-field>
                                    <span class="red--text note-text"
                                      v-html="$t('invoice.createForm.salesPersonNameNote')"></span>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pt-0">
                                    <v-textarea rows="2" v-model="invoiceForm.payment.notes"
                                      :placeholder="$t('invoice.createForm.internalDismantlerNotes')" solo dense
                                      hide-details="auto" maxlength="150">
                                    </v-textarea>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="6">
                                <div class="box-wrap">
                                  <div v-if="invoiceForm.work_order == 1">
                                  <v-row class="text-left">
                                    <v-col>
                                      <div class="d-flex">
                                        <v-checkbox v-model="invoiceForm.payment.budget_tax_into_customers_sales_price"
                                          class="mt-0 pt-0" hide-details="auto"></v-checkbox>
                                        <label class="mb-0">{{ $t('invoice.createForm.budgetTaxIntoCustomersSalesPrice')
                                          }}</label>
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                              mdi-help
                                            </v-icon>
                                          </template>
                                          <span v-html="$t('invoice.createForm.budgetNotes')"></span>
                                        </v-tooltip>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="8" class="pt-0 pb-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.subtotal") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4> {{ invoiceForm.payment.itemtotal | USCurrencyfilter }} </h4>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="!invoiceForm.payment.budget_tax_into_customers_sales_price">
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-0 rd-item-align-center">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.discount") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-0">
                                      <v-select :items="discountOption" item-text="value" item-value="key"
                                        v-model="invoiceForm.payment.discount"
                                        :placeholder="$t('invoice.createForm.discount')" solo dense
                                        hide-details="auto"></v-select>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="8" class="pt-0 pb-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.discountAmount") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4>- {{ invoiceForm.payment.discount_amount |
                                        USCurrencyfilter }}</h4>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="8" class="pt-0 pb-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.subtotalAfterDiscount") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4> {{ invoiceForm.payment.subtotal | USCurrencyfilter }}</h4>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="8" class="pt-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.salesTaxAmount") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4>{{ invoiceForm.payment.sales_tax_amount | USCurrencyfilter }}</h4>
                                    </v-col>
                                  </v-row>
                                  <div v-if="invoiceForm.customer.ship_is_shipping_required">
                                    <v-row>
                                      <v-col cols="12">
                                        <hr style="border: 1px solid #dcd9d9;" />
                                      </v-col>
                                      <v-col cols="6" class="pt-0 pb-0 rd-item-align-center">
                                        <label class="mb-0">
                                          <span>
                                            {{ $t("invoice.createForm.shippingCharges") }}
                                          </span>
                                        </label>
                                      </v-col>
                                      <v-col cols="6" class="pt-0">
                                        <v-text-field type="number" v-model="invoiceForm.payment.shipping_charges"
                                          :placeholder="$t('invoice.createForm.shippingCharges')" solo dense
                                          hide-details="auto" hide-spin-buttons>
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </div>
                                <v-row>
                                  <v-col cols="12">
                                    <hr style="border: 1px solid #dcd9d9;" />
                                  </v-col>
                                  <v-col cols="8" class="pt-0">
                                    <label class="mb-0">
                                      <b>
                                        {{ $t("invoice.createForm.total") }}
                                      </b>
                                    </label>
                                  </v-col>
                                  <v-col cols="4" class="pt-0 pb-0 text-right">
                                    <h4>{{ getTotalAmount() | USCurrencyfilter }}</h4>
                                  </v-col>
                                </v-row>
                                <div
                                  v-if="invoiceForm.payment.payment_term == 'billing_payments' && invoiceForm.payment.down_payment">
                                  <v-row>
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="8" class="pt-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.deposit") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4>- {{ parseFloat(invoiceForm.payment.deposit_amount ?
                                        invoiceForm.payment.deposit_amount : 0) |
                                        USCurrencyfilter }}</h4>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="8" class="pt-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.pendingBalance") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4>{{ getTotalAmount() - parseFloat(invoiceForm.payment.deposit_amount ?
                                        invoiceForm.payment.deposit_amount : 0)
                                        | USCurrencyfilter }}</h4>
                                    </v-col>
                                  </v-row>
                                </div>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col col="12">
                            <div class="d-flex mt-2">
                              <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text" @click.native="goBack('form3')">
                                <v-icon size="19" class="white--text mr-1">mdi-arrow-left</v-icon> {{
                                  $t('invoice.previous')
                                }}
                              </v-btn>
                              <v-spacer />
                              <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text" @click.native="stepContinue('form4')">
                                {{ $t('invoice.next') }} <v-icon size="19"
                                  class="white--text ml-1">mdi-arrow-right</v-icon>
                              </v-btn>
                            </div>
                            </v-col>
                            </v-row>
                          </form>
                        </div>
                        <!-- </div> -->
                      </v-stepper-content>
                      <v-stepper-content step="5">
                        <!-- <div class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"> -->
                        <v-responsive class="pa-0 flex-grow-1">
                          <form data-vv-scope="form5">
                            <v-row class="mt-1">
                              <v-col cols="6">
                                <v-row>
                                  <v-col cols="6">
                                    <strong>{{ $t("invoice.createForm.customerInformation") }}</strong>
                                    <span v-html="customerInformation()"></span>
                                  </v-col>
                                  <v-col cols="6">
                                    <strong>{{ $t("invoice.createForm.delivery") }}</strong>
                                    <span v-html="shippingInformation()"></span>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <strong>{{ $t("invoice.createForm.salesPersonName") }}:</strong>
                                    {{ invoiceForm.payment.sales_person_name }}
                                  </v-col>
                                </v-row>
                                <hr class="mt-3 mb-3" style="border: 1px solid #dcd9d9;" />
                                <v-row>
                                  <v-col cols="12">
                                    <v-row>
                                      <v-col col="12">
                                        <div class="mb-3">
                                          <strong>{{ $t("invoice.createForm.partsPurchase") }}</strong>
                                        </div>
                                        <!-- <v-simple-table dense> -->
                                        <table class="inr-custom-table">
                                          <thead>
                                            <tr>
                                              <th class="text-left" width="30%">{{ $t("invoice.createForm.name") }}</th>
                                              <th class="text-left" width="33%">{{ $t("invoice.createForm.carMakeModel")
                                                }}</th>
                                              <th class="text-left" width="15%">{{ $t("invoice.createForm.warranty") }}
                                              </th>
                                              <th class="text-left" width="12%">{{ $t("invoice.createForm.amount") }}
                                              </th>
                                              <!-- <th class="text-left" width="22%">Action</th> -->
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-for="(item, index) in invoiceForm.items" :key="index">
                                              <td>{{ item.part_name }}</td>
                                              <td>{{ item.year }} {{ item.make }} {{ item.model }}</td>
                                              <td>{{ item.warranty }}</td>
                                              <td>{{ item.price | USCurrencyfilter }}</td>
                                              <!-- <td>
                                              <a v-if="item.is_custom" @click="editCustomPartDialog(item)">
                                                <v-icon>mdi-pencil</v-icon>
                                              </a>
                                              <a v-if="!item.is_custom" @click="editPartDialogOpen(item)">
                                                <v-icon>mdi-pencil</v-icon>
                                              </a>
                                              <a @click="deleteFromSelected(item)">
                                                <v-icon>mdi-delete</v-icon>
                                              </a>
                                            </td> -->
                                            </tr>
                                          </tbody>
                                        </table>
                                        <!-- </v-simple-table> -->
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="6">
                                <div class="box-wrap">
                                <v-row>
                                  <v-col cols="8" class="pb-0">
                                    <label class="mb-0">
                                      <span>
                                        {{ $t("invoice.createForm.subtotal") }}
                                      </span>
                                    </label>
                                  </v-col>
                                  <v-col cols="4" class="pb-0 text-right">
                                    <h4>{{ invoiceForm.payment.itemtotal | USCurrencyfilter }}</h4>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <hr style="border: 1px solid #dcd9d9;" />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="8" class="pt-0 pb-0">
                                    <label class="mb-0">
                                      <span>
                                        {{ $t("invoice.createForm.discountAmount") }}
                                      </span>
                                    </label>
                                  </v-col>
                                  <v-col cols="4" class="pt-0 pb-0 text-right">
                                    <h4>- {{ invoiceForm.payment.discount_amount |
                                      USCurrencyfilter }}</h4>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <hr style="border: 1px solid #dcd9d9;" />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="8" class="pt-0 pb-0">
                                    <label class="mb-0">
                                      <span>
                                        {{ $t("invoice.createForm.subtotalAfterDiscount") }}
                                      </span>
                                    </label>
                                  </v-col>
                                  <v-col cols="4" class="pt-0 pb-0 text-right">
                                    <h4>{{ invoiceForm.payment.subtotal | USCurrencyfilter }}</h4>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <hr style="border: 1px solid #dcd9d9;" />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="8" class="pt-0 pb-0">
                                    <label class="mb-0">
                                      <span>
                                        {{ $t("invoice.createForm.salesTaxAmount") }}
                                      </span>
                                    </label>
                                  </v-col>
                                  <v-col cols="4" class="pt-0 pb-0 text-right">
                                    <h4>{{ invoiceForm.payment.sales_tax_amount | USCurrencyfilter }}</h4>
                                  </v-col>
                                </v-row>
                                <v-row v-if="invoiceForm.customer.ship_is_shipping_required">
                                  <v-col cols="12">
                                    <v-row>
                                  <v-col cols="12">
                                    <hr style="border: 1px solid #dcd9d9;" />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="6" class="pt-0 pb-0">
                                    <label class="mb-0">
                                      <span>
                                        {{ $t("invoice.createForm.shippingCharges") }}
                                      </span>
                                    </label>
                                  </v-col>
                                  <v-col cols="6" class="pt-0 pb-0 text-right">
                                    <h4>{{ invoiceForm.payment.shipping_charges |
                                      USCurrencyfilter }}</h4>
                                  </v-col>
                                  </v-row>
                                </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <hr style="border: 1px solid #dcd9d9;" />
                                  </v-col>
                                  <v-col cols="8" class="pt-0">
                                    <label class="mb-0">
                                      <b>
                                        {{ $t("invoice.createForm.total") }}
                                      </b>
                                    </label>
                                  </v-col>
                                  <v-col cols="4" class="pt-0 text-right">
                                    <h4>{{ getTotalAmount() | USCurrencyfilter }}</h4>
                                  </v-col>
                                </v-row>
                                <div
                                  v-if="invoiceForm.payment.payment_term == 'billing_payments' && invoiceForm.payment.down_payment">
                                  <v-row>
                                  <v-col cols="12">
                                    <v-row>
                                  <v-col cols="12">
                                    <hr style="border: 1px solid #dcd9d9;" />
                                  </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="8" class="pt-0 pb-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.deposit") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4>- {{ parseFloat(invoiceForm.payment.deposit_amount ?
                                        invoiceForm.payment.deposit_amount : 0) |
                                        USCurrencyfilter }}</h4>
                                    </v-col>
                                  </v-row>
                                  </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <hr style="border: 1px solid #dcd9d9;" />
                                    </v-col>
                                    <v-col cols="8" class="pt-0 pb-0">
                                      <label class="mb-0">
                                        <span>
                                          {{ $t("invoice.createForm.pendingBalance") }}
                                        </span>
                                      </label>
                                    </v-col>
                                    <v-col cols="4" class="pt-0 pb-0 text-right">
                                      <h4>{{ getTotalAmount() - parseFloat(invoiceForm.payment.deposit_amount ?
                                        invoiceForm.payment.deposit_amount : 0) | USCurrencyfilter }}</h4>
                                    </v-col>
                                  </v-row>
                                </div>
                              </div>
                              </v-col>
                            </v-row>
                            <v-row>
                             <v-col col="12">
                              <div class="d-flex mt-3 mb-3">
                              <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text"  @click.native="goBack('form4')">
                                <v-icon size="19" class="white--text mr-1">mdi-arrow-left</v-icon> {{
                                  $t('invoice.previous')
                                }}
                              </v-btn>
                              <v-spacer />
                              <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text" @click.native="stepContinue('form5')">
                                {{ $t('invoice.submit') }}
                              </v-btn>
                            </div>
                             </v-col>
                            </v-row>
                          </form>
                        </v-responsive>
                        <!-- </div> -->
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- Part Listing Screen -->
        <div class="d-flex flex-column fill-height" v-if="isPartScreen">
          <div class="box-top-wrap d-flex align-center justify-space-between">
            <div class="item-row">
              <button @click="$router.go(-1)" class="text-capitalize
              btn customBtn pattern-btn
              white--text" elevation="0">
                <span> {{ $t("invoice.back") }}</span>
              </button>
              <h3 class="box-title green-text">
                <label>
                  <span v-if="beginYear">
                    {{
                      (beginYear ? beginYear : "") +
                      " " +
                      (make ? make.MfrName + " " + (model ? model.ModelNm : '') : "") +
                      " " +
                      (partType ? partType.Description : "")
                    }}
                  </span>
                  <span v-if="!beginYear">{{ $t("interchange.selectedParts") }}</span>
                </label>
              </h3>
            </div>
          </div>
          <div class="custom-card selected-parts">
            <div class="custom-box">
              <template>
                <!-- <div class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
                <v-responsive class="custom-chat-height-wrapper">
                  <v-container fluid class="pa-0"> -->
                <v-data-table :headers="headers" :items="partsListAll" item-key="id" fixed-header
                  class="color-light-green booklog-table-wrapper" :loading="loading" hide-default-footer>
                  <template v-slot:item.selected="{ item }">
                    <v-checkbox class="pr-0 pt-0 mt-0" style="height: 25px" v-model="invoiceForm.items"
                      :value="item"></v-checkbox>
                  </template>
                  <template v-slot:item.part_name="{ item }">
                    {{ item.part_name }}
                  </template>
                  <template v-slot:item.year="{ item }">
                    {{ item.year }} {{ item.make }} {{ item.model }}
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ item.price | USCurrencyfilter }}
                  </template>
                  <template v-slot:item.warranty="{ item }">
                    {{ item.warranty }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div class="action-btn-row">
                      <a v-if="item.is_custom" class="mr-2 action-btn" @click="editCustomPartDialog(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </a>
                      <a v-if="!item.is_custom" class="mr-2 action-btn" @click="editPartDialogOpen(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </a>
                    </div>
                    <!-- <a @click="deleteFromSelected(item)">
                          <v-icon>mdi-delete</v-icon>
                        </a> -->
                  </template>
                </v-data-table>
                <div class="selected-parts-footer">
                  <p class="not-carry mb-4" v-html="$t('invoice.createForm.invoicePartList')"></p>
                  <div class="d-flex">
                    <button class="btn customBtn pattern-btn
                        text-capitalize white-text" @click="backFromPartScreen">
                      <span> {{ $t('invoice.addMoreParts') }}</span>
                    </button>
                    <v-spacer />
                    <v-btn class="btn customBtn pattern-btn
                        text-capitalize white-text font-16"
                      @click.native="stepContinue('form1'), isPartScreen = false, beginYear = null, make = {}, model = {}, partType = null"
                      :disabled="!invoiceForm.items.length">
                      <span> {{ $t('invoice.next') }} <v-icon size="19"
                          class="white--text ml-1">mdi-arrow-right</v-icon></span>
                    </v-btn>
                  </div>
                </div>
                <!-- </v-container>
                </v-responsive>
              </div> -->
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="inside-model-boxs">
        <v-dialog hide-overlay :attach="true" v-model="addCustomPartDialog" max-width="700px"
          :content-class="'pa-0 flex-grow-1 rd-custom-modal'" persistent>
          <v-card>
            <v-card-title v-if="!isCustomPartEdit" class="pb-0">
              <h4>{{ $t("invoice.createForm.addCustomPartTitle")
                }}</h4>
              <button class="close-btn"
                @click="resetCustomPartsForm(), addCustomPartDialog = !addCustomPartDialog; isCustomPartEdit = false;">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-card-title v-if="isCustomPartEdit" class="pb-0">
              <h4>{{ $t("invoice.createForm.editCustomPartTitle")
                }}</h4>
              <button class="close-btn"
                @click="resetCustomPartsForm(), addCustomPartDialog = !addCustomPartDialog; isCustomPartEdit = false;">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-form>
              <div>
                <v-form>
                  <v-card-text class="field__card">
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-autocomplete height="45" v-model="customPartForm.partYear" :items="yearList"
                          item-text="CarlineYear" item-value="CarlineYear" @change="onChangeYear('custom')" clearable
                          solo dense hide-details :placeholder="$t('invoice.createForm.carLineYear')"
                          :loading="modelYearLoading">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-autocomplete :disabled="!customPartForm.partYear" height="45" v-model="customPartForm.make"
                          :items="makeList" item-text="label" item-value="MfrName" @change="fetchModel('custom')"
                          return-object clearable solo dense hide-details :placeholder="$t('interchange.hollanderMake')"
                          :loading="customModelLoading">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-autocomplete :disabled="!customPartForm.make" height="45" v-model="customPartForm.model"
                          :items="modelList" item-text="ModelNm" item-value="ModelNm" return-object clearable solo dense
                          hide-details :placeholder="$t('interchange.hollanderModel')" :loading="customModelLoading">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field hide-details v-model="customPartForm.partName" elevation="0" dense solo
                          :placeholder="$t('invoice.createForm.partName')"></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field hide-details type="number" hide-spin-buttons v-model="customPartForm.partPrice"
                          :placeholder="$t('invoice.createForm.partPrice')" dense solo></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-select id="input-10" class="" :items="warrantyOptions" v-model="customPartForm.warrantyDays"
                          :placeholder="$t('invoice.createForm.partWarranty')" solo dense
                          hide-details="auto"></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize font-16"
                      @click="resetCustomPartsForm(), addCustomPartDialog = !addCustomPartDialog; isCustomPartEdit = false;">
                      <span> {{ $t("invoice.close") }}</span>
                    </v-btn>
                    <v-btn class="btn customBtn pattern-btn text-capitalize ml-2 white--text font-16" :loading="saving"
                      @click="saveCustomParts"
                      :disabled="!customPartForm.partYear || !customPartForm.make || !customPartForm.model || !customPartForm.partName || !customPartForm.partPrice || !customPartForm.warrantyDays">
                      <span> {{ !isCustomPartEdit ? $t("invoice.createForm.addCustomPart") :
                        $t("invoice.createForm.updateCustomPart") }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </div>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog hide-overlay :attach="true" v-model="editPartDialog" max-width="500px"
          :content-class="'image-slider rd-custom-modal'" persistent>
          <v-card>
            <v-card-title> 
              <h4>{{ $t("invoice.createForm.editPartTitle") }}</h4>
              <button class="close-btn"
              @click="resetPartsForm(), editPartDialog = !editPartDialog;">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
                <v-form>
                  <div>
                    <v-form>
                   <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field type="number" hide-details="auto" hide-spin-buttons v-model="partForm.partPrice"
                            :placeholder="$t('invoice.createForm.partPrice')" dense solo></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-select id="input-10" :items="warrantyOptions"
                            v-model="partForm.warrantyDays" :placeholder="$t('invoice.createForm.partWarranty')"
                            dense solo></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize font-16"
                          @click="resetPartsForm(), editPartDialog = !editPartDialog;">
                          <span>{{ $t("invoice.close") }}</span>
                        </v-btn>
                        <v-btn class="btn customBtn pattern-btn text-capitalize ml-2 white--text font-16"
                          :loading="saving" @click="saveParts">
                          <!-- :disabled="!emailForm.name || !emailForm.email" -->
                        <span>{{ $t("invoice.createForm.updatePart") }}</span>
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </div>
                </v-form>
          </v-card>
        </v-dialog>
        <v-dialog hide-overlay :attach="true" v-model="errorDailog" max-width="550px"
          :content-class="'image-slider rd-custom-modal'" persistent>
          <v-card>
            <v-card-title>
              <h4></h4>
              <button class="close-btn"
                @click="errorDailog = !errorDailog, beginYear = null, make = null, model = null, partType = null">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
              <div class="text-center">
                <v-card-title class="pa-0" style="flex-direction: column;"> {{
                  $t("invoice.createForm.noPartResultFound")
                }}</v-card-title>
                <span> {{
                  $t("invoice.createForm.noPartResultFound2") }}</span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize font-16"
                @click="errorDailog = !errorDailog, beginYear = null, make = null, model = null, partType = null">
                <span>{{ $t("invoice.close") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog hide-overlay :attach="true" v-model="conformDailog" max-width="500px"
          :content-class="'image-slider rd-custom-modal'" persistent>
          <v-card>
            <v-card-title>
              <h4></h4>
              <button class="close-btn"
              @click="conformDailog = !conformDailog;">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
              <div class="text-center">
                <v-card-title class="pa-0" style="flex-direction: column;"> {{ $t("invoice.createForm.confirmSubmitText")
                  }}</v-card-title>
                  <span v-html="$t('invoice.createForm.confirmSubmitNoteText')"></span>
              </div>
            </v-card-text>
            <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize font-16"
                      @click="conformDailog = !conformDailog;">
                      {{ $t("invoice.close") }}
                    </v-btn>
                    <v-btn class="btn customBtn pattern-btn white--text text-capitalize font-16" @click="saveInvoiceData()"
                      :loading="saving" :disabled="isSaving">
                      {{ $t("invoice.createInvoice") }}
                    </v-btn>
                  </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-snackbar v-model="snackbarSuccess" :timeout="3000" absolute top color="success" right>
        {{ $t("marketingCampaign.compaignStart") }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarSuccess = false">
            {{ $t("payment.close") }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="snackbarError" :timeout="3000" absolute top color="error" right>
        {{ apiErrorMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarError = false">
            {{ $t("payment.close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// import { showSnackbar } from "../../eventbus/action.js";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { find, some, join, map, reverse, get } from "lodash";
import { WARRANTY_OPTIONS } from "@/constants/common";
import GooglePlace from "../common/GooglePlace.vue";
import { VueTelInput } from "vue-tel-input";
import PhoneNumber from 'awesome-phonenumber';

export default {
  components: {
    GooglePlace,
    VueTelInput,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      valid: true,
      e1: 1,
      userDetail: {},
      mfrCd: null,
      isStepHidden: false,
      loading: false,
      saving: false,
      isSaving: false,
      apiErrorMessage: null,
      snackbarSuccess: false,
      snackbarError: false,
      partsListSearch: [],
      partsListAll: [],
      conformDailog: false,
      customerSuggestions: [],

      //setp 1
      isPartScreen: false,
      addCustomPartDialog: false,
      editPartDialog: false,
      errorDailog: false,
      isCustomPartEdit: false,
      warrantyOptions: WARRANTY_OPTIONS,
      customPartId: null,
      customPartForm: {
        partYear: null,
        make: null,
        model: null,
        partName: null,
        partPrice: null,
        warrantyDays: null,
      },
      partForm: {
        id: null,
        partPrice: null,
        warrantyDays: null,
      },
      beginYear: null,
      make: null,
      model: null,
      modelList: [],
      makeList: [],
      yearList: [],
      partType: null,
      modelYearLoading: false,
      partTypeLoading: false,
      searchModelLoading: false,
      customModelLoading: false,

      //setp 2
      customersSearchLoading: false,
      isStep2NextDisabled: false,
      queryTerm: null,
      queryBusinessTerm: null,
      typeOption: [
        { key: "body_shop", "value": "Body Shop" },
        { key: "dismantler", "value": "Dismantler" },
        { key: "customer", "value": "Customer" }
      ],

      //step 3
      useCustomerAddress: null,

      //step 4
      paymentTermOption: [
        { key: "full_charge", "value": "Full Charge" },
        { key: "billing_payments", "value": "Billing Payments" },
      ],
      paymentMethodOption: [
        { key: "cash", "value": "Cash" },
        { key: "credit_card_number", "value": "CC" },
        { key: "cheque", "value": "Cheque" },
        { key: "cod", "value": "COD" },
      ],
      paymentDueDaysOption: [
        { key: "5", value: "5 Days" },
        { key: "7", value: "7 Days" },
        { key: "10", value: "10 Days" },
        { key: "15", value: "15 Days" },
        { key: "30", value: "30 Days" },
        { key: "45", value: "45 Days" },
        { key: "60", value: "60 Days" },
        { key: "90", value: "90 Days" },
      ],
      discountOption: [
        { key: 0, value: this.$t("invoice.createForm.noDiscount") },
        { key: 5, value: "5%" },
        { key: 10, value: "10%" },
        { key: 15, value: "15%" },
        { key: 20, value: "20%" },
        { key: 25, value: "25%" },
        { key: 30, value: "30%" },
        { key: 35, value: "35%" },
        { key: 40, value: "40%" },
        { key: 45, value: "45%" },
        { key: 50, value: "50%" },
      ],

      //final form field
      invoiceForm: {
        //step 1
        items: [],
        //step 2
        work_order: null,
        customer_type: null,
        customer: {
          business_name: null,
          business_phone_country_code: 1,
          business_phone: null,
          bd_customer_name: null,
          customer_name: null,
          customer_phone_country_code: 1,
          customer_phone: null,
          is_tax_resale_number: false,
          tax_resale_number: null,
          address: null,
          city: null,
          state: null,
          zip: null,
          //step 3
          ship_is_shipping_required: false,
          ship_customer_name: null,
          ship_customer_phone: null,
          ship_customer_phone_country_code: 1,
          ship_address: null,
          ship_city: null,
          ship_state: null,
          ship_zip: null,
          ship_note: null,
          //end step 3
        },
        //step 4
        payment: {
          payment_term: null,
          payment_method: null,
          payment_due_days: 0,
          down_payment: false,
          credit_card_number: null,
          bank_name: null,
          cheque_number: null,
          sales_person_name: null,
          notes: null,
          budget_tax_into_customers_sales_price: false,
          subtotal: 0,
          deposit_amount: null,
          itemtotal: 0,
          discount: null,
          discount_amount: null,
          sales_tax_amount: null,
          shipping_charges: null,
        },
      },

      headers: [
        { text: "", value: "selected", sortable: false, width: "7%" },
        {
          text: this.$t("searchInventory.partName"),
          align: "left",
          sortable: false,
          value: "part_name",
          width: "15%",
        },
        {
          text: this.$t("searchInventory.yearMakeModel"),
          align: "left",
          sortable: false,
          value: "year",
          width: "25%",
        },
        {
          text: this.$t("searchInventory.price"),
          align: "left",
          sortable: false,
          value: "price",
          width: "15%",
        },
        {
          text: this.$t("searchInventory.warranty"),
          align: "left",
          sortable: false,
          value: "warranty",
          width: "12%",
        },
        {
          text: this.$t("searchInventory.actions"),
          align: "left",
          sortable: false,
          value: "actions",
          width: "12%",
        },
      ],
    };
  },
  validations: {
    data: {
      message: { required, maxLength: maxLength(100) },
    },
  },
  watch: {
    'invoiceForm.items': function (newPart, oldPart) {
      this.invoiceForm.items.map(v => {
        return v.selected = true
      });
      this.invoiceForm.payment.itemtotal = this.invoiceForm.items.map(item => parseFloat(item.price)).reduce((prev, next) => parseFloat(prev) + parseFloat(next));
      this.calculateAmount();
    },
    'invoiceForm.customer_type': function (newType, oldType) {
      this.resetCustomerTypeForm();
      this.vErrors.clear();
    },
    useCustomerAddress: function (newValue, oldValue) {
      if (newValue == 'yes') {
        this.invoiceForm.customer.ship_customer_name = (this.invoiceForm.customer_type == 'customer') ? this.invoiceForm.customer.customer_name : this.invoiceForm.customer.bd_customer_name;
        this.invoiceForm.customer.ship_customer_phone = this.invoiceForm.customer.customer_phone ? this.invoiceForm.customer.customer_phone : '';
        this.invoiceForm.customer.ship_address = this.invoiceForm.customer.address;
        this.invoiceForm.customer.ship_state = this.invoiceForm.customer.state;
        this.invoiceForm.customer.ship_city = this.invoiceForm.customer.city;
        this.invoiceForm.customer.ship_zip = this.invoiceForm.customer.zip;
      } else {
        this.resetShippingForm();
      }
    },
    'invoiceForm.payment.discount': function (newVal, oldVal) {
      this.calculateAmount();
    },
    'invoiceForm.payment.itemtotal': function (newVal, oldVal) {
      this.calculateAmount();
    },
    'invoiceForm.payment.budget_tax_into_customers_sales_price': function (newVal, oldVal) {
      this.calculateAmount();
    },
    'invoiceForm.work_order': function (newVal, oldVal) {
      this.isStep2NextDisabled = true;
      this.calculateAmount();
    },
    'invoiceForm.customer.business_name': function (newVal, oldVal) {
      console.log("business_name: ", newVal);
      if (!newVal || newVal.length == 0) {
        this.customerSuggestions = [];
      }
      if (newVal != oldVal) this.queryBusinessTerm = newVal ? newVal.toUpperCase() : newVal;
      this.invoiceForm.customer.business_name = newVal ? newVal.toUpperCase() : newVal;
    },
    'invoiceForm.customer.customer_name': function (newVal, oldVal) {
      if (!newVal || newVal.length == 0) {
        this.customerSuggestions = [];
      }
      if (newVal != oldVal) this.queryTerm = newVal ? newVal.toUpperCase() : newVal;
      this.invoiceForm.customer.customer_name = newVal ? newVal.toUpperCase() : newVal;
    },
    'invoiceForm.customer.bd_customer_name': function (newVal, oldVal) {
      this.invoiceForm.customer.bd_customer_name = newVal ? newVal.toUpperCase() : newVal;
    },
    'invoiceForm.customer.ship_customer_name': function (newVal, oldVal) {
      // this.invoiceForm.customer.ship_customer_name = newVal ? newVal.toUpperCase() : '';
    },
    'invoiceForm.payment.shipping_charges': function (newVal, oldVal) {
      if (!newVal || newVal == '') {
        this.invoiceForm.payment.shipping_charges = null;
      }
    },
    'invoiceForm.payment.deposit_amount': function (newVal, oldVal) {
      if (!newVal || newVal == '') {
        this.invoiceForm.payment.deposit_amount = null;
      }
    },
    'invoiceForm.payment.payment_term': function (newVal, oldVal) {
      this.invoiceForm.payment.payment_method = null;
      this.invoiceForm.payment.payment_due_days = 0;
      this.invoiceForm.payment.down_payment = false;
      this.invoiceForm.payment.credit_card_number = null;
      this.invoiceForm.payment.bank_name = null;
      this.invoiceForm.payment.cheque_number = null;
    },
  },
  computed: {
    ...mapGetters({
      yearOptions: "hollander/getYears",
      makeOptions: "hollander/getMake",
      modelOptions: "hollander/getModel",
      salesTaxRate: "user/getSalesTaxRate",
      modelYears: "invoice/getModelYears",
      manufacturer: "invoice/getManufacturer",
      carline: "invoice/getCarline",
      pType: "invoice/getPType",
      partsList: "invoice/getPartsList",
      customersList: "invoice/getCustomersList",
    }),
    searchBusiness: {
      get() {
        return this.queryBusinessTerm
      },
      set(searchInput) {
        this.invoiceForm.customer.business_name = searchInput;
        if (this.queryBusinessTerm && this.queryBusinessTerm != searchInput) {
          this.queryBusinessTerm = searchInput;
          this.fetchCustomersList(this.queryBusinessTerm);
        } else {
          this.customerSuggestions = [];
        }
      }
    },
    searchCustomers: {
      get() {
        return this.queryTerm
      },
      set(searchInput) {
        this.invoiceForm.customer.customer_name = searchInput;
        if (this.queryTerm && this.queryTerm != searchInput) {
          this.queryTerm = searchInput;
          this.fetchCustomersList(this.queryTerm);
        } else {
          this.customerSuggestions = [];
        }
      }
    }
  },
  async mounted() {
    this.invoiceForm.payment.discount = 0;
    this.userDetail = localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : {};
    if (this.userDetail) {
      // this.salesTaxRate = this.userDetail.sales_tax_rate;
      this.invoiceForm.payment.sales_person_name = this.userDetail.user_detail.representative_name;
    }
    await this.fetchSalesTaxRate();

    await this.setYear();
    await this.setMake();

    // this.e1 = 4;
    // this.invoiceForm.work_order = true;
    /*
    this.invoiceForm.items = [
      {
        id: "x5za6g1u2o",
        is_custom: true,
        make: "General Motors",
        model: "SUBURBAN 1500",
        part_name: "ghjghjgh",
        price: "7567",
        selected: true,
        warranty: "5 days",
        year: 2022
      },
      {
        id: 12,
        is_custom: true,
        make: "Motors",
        model: "1500",
        part_name: "ttttt",
        price: "100",
        selected: true,
        warranty: "7 days",
        year: 2021
      }
    ];
    */
  },
  methods: {
    ...mapActions({
      getYears: "hollander/getYears",
      getMake: "hollander/getMake",
      getModel: "hollander/getModel",
      getSalesTaxRate: "user/getSalesTaxRate",
      getModelYears: "invoice/getModelYears",
      getManufacturer: "invoice/getManufacturer",
      getCarline: "invoice/getCarline",
      getPType: "invoice/getPType",
      getPartsList: "invoice/getPartsList",
      getCustomersList: "invoice/getCustomersList",

      saveInvoice: "invoice/saveInvoice",
    }),
    async fetchSalesTaxRate() {
      this.$store.commit("setLoader", true);
      try {
        await this.getSalesTaxRate();
        if (this.salesTaxRate.status == false) {
          this.apiErrorMessage = this.salesTaxRate.message;
          this.snackbarError = true;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async onChangeYear(type = 'search') {
      if (type == 'search') {
        this.make = null;
        this.model = null;
        this.partType = {};
      } else {
        this.customPartForm.make = null;
        this.customPartForm.model = null;
      }
    },

    async fetchModel(type = 'search') {
      if (type == 'search') {
        this.model = null;
        this.partType = {};
        var year = this.beginYear;
        var make = this.make;
      } else {
        this.customPartForm.model = null;
        var year = this.customPartForm.partYear ?? null;
        var make = this.customPartForm.make ?? null;
      }
      await this.setModel(make);
    },

    async setYear() {
      this.yearList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getYears();
        this.yearList = await [...this.yearOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async setMake() {
      this.makeList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getMake({});
        this.makeList = await [...this.makeOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async setModel(make) {
      this.modelList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getModel({
          MfrCd: make.MfrCd,
        });
        this.modelList = await [...this.modelOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async fetchPartType() {
      this.partTypeLoading = true;
      this.partType = {};
      if (this.make && this.model && this.beginYear) {
        try {
          this.mfrCd = this.make.MfrCd;
          await this.fetchManufacturers();
          await this.getPType();
        } catch ({ message }) {
          this.apiErrorMessage = message;
        } finally {
          this.partTypeLoading = false;
        }
      } else if (this.data.inventoryType === "thirdParty") {
        await this.getPType();
        this.partTypeLoading = false;
      } else {
        this.partTypeLoading = false;
      }
    },
    async fetchPartsList() {
      try {
        await this.getPartsList({
          make: this.make.MfrName,
          model: this.model.ModelNm,
          part_name: this.partType.Description,
          years: [this.beginYear],
        });
        if (this.partsList.length == 0) {
          // showSnackbar("The part you are looking for is not inside your inventory");
          this.errorDailog = true;
        }

        this.partsListSearch = this.partsList.map(function (val) {
          return {
            id: val.id,
            part_name: val.part_name,
            price: val.recommended_sale_price,
            warranty: val.warranty_days,
            make: val.car_profile.make,
            model: val.car_profile.model,
            year: val.car_profile.year,
            is_custom: false,
          };
        });

        this.isPartScreen = this.partsListSearch.length > 0 ? true : false;
        this.partsListAll = [...this.invoiceForm.items, ...this.partsListSearch];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {

      }
    },
    async fetchManufacturers() {
      this.manufacturerLoading = true;
      try {
        await this.getManufacturer({ MfrCd: this.mfrCd });
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.manufacturerLoading = false;
      }
    },
    backFromPartScreen() {
      this.e1 = 1;
      this.beginYear = null;
      this.make = null;
      this.model = null;
      this.partType = null;

      this.partsListSearch = []
      this.partsListAll = [...this.invoiceForm.items, ...this.partsListSearch];
      this.isPartScreen = false;
    },
    async saveCustomParts() {
      let partData = {
        id: Math.random().toString(36).slice(2),
        part_name: this.customPartForm.partName,
        price: this.customPartForm.partPrice,
        warranty: this.customPartForm.warrantyDays ? this.customPartForm.warrantyDays : '0 days',
        make: this.customPartForm.make.MfrName,
        model: this.customPartForm.model.ModelNm,
        year: this.customPartForm.partYear,
        is_custom: true,
      };

      this.beginYear = partData.year;
      const partDataMake = partData.make;
      let make = null;
      let partDataModel = null
      if (partData.model) {
        partDataModel = partData.model.split(" ")[0];
      }
      let model = null;
      this.makeList.map(function (element) {
        if (element.MfrName === partDataMake) {
          make = element;
        }
      });

      if (make) {
        this.make = make;

        await this.setModel();
        await this.modelList.map(function (element) {
          if (element.ModelNm === partDataModel.toUpperCase()) {
            model = element;
          }
        });

        if (model) {
          this.model = model;
        }
      }

      this.partType = { Description: partData.part_name };

      if (this.customPartId) {
        this.invoiceForm.items = this.invoiceForm.items.filter(function (obj) {
          return obj.id !== this.customPartId;
        }, { customPartId: this.customPartId });
      }
      this.invoiceForm.items.push(partData);
      this.partsListAll = [...this.invoiceForm.items, ...this.partsListSearch];
      this.addCustomPartDialog = !this.addCustomPartDialog;

      this.isPartScreen = true;
      this.resetCustomPartsForm();
    },
    async editCustomPartDialog(item) {
      this.addCustomPartDialog = true;
      this.isCustomPartEdit = true;
      this.customPartId = item.id;

      this.customPartForm = {
        partYear: item.year,
        partName: item.part_name,
        partPrice: item.price,
        warrantyDays: item.warranty,
      };

      const itemMake = item.make;
      let make = null;
      let itemModel = null
      if (item.model) {
        itemModel = item.model.split(" ")[0];
      }
      let model = null;
      this.makeList.map(function (element) {
        if (element.MfrName === itemMake) {
          make = element;
        }
      });

      if (make) {
        this.customPartForm.make = make;

        await this.setModel();
        await this.modelList.map(function (element) {
          if (element.ModelNm === itemModel.toUpperCase()) {
            model = element;
          }
        });

        if (model) {
          this.customPartForm.model = model;
        }
      }
    },
    resetCustomPartsForm() {
      this.customPartForm = {
        partYear: null,
        make: null,
        model: null,
        partName: null,
        partPrice: null,
        warrantyDays: null,
      };
      this.customPartId = null;
      this.$v.$reset()
    },
    editPartDialogOpen(item) {
      this.partForm = {
        id: item.id,
        partPrice: item.price,
        warrantyDays: item.warranty,
      };
      this.editPartDialog = true;
    },
    deleteFromSelected(data) {
      if (this.invoiceForm.items.length > 1) {
        this.invoiceForm.items = this.invoiceForm.items.filter(item => {
          if (item.id != data.id) {
            return item
          }
        });
      } else {
        alert("You need at list one item for create bill.");
      }
    },
    saveParts() {
      let partInfo = this.partsListAll.find(o => o.id === this.partForm.id);
      partInfo.price = this.partForm.partPrice;
      partInfo.warranty = this.partForm.warrantyDays;

      //remove from array
      this.partsListAll = this.partsListAll.filter(function (obj) {
        return obj.id !== this.id;
      }, this.partForm);

      this.partsListAll = [...[partInfo], ...this.partsListAll];
      this.editPartDialog = false;
      this.resetPartsForm();
    },
    resetPartsForm() {
      this.partForm = {
        id: null,
        partPrice: null,
        warrantyDays: null,
      };
      this.$v.$reset()
    },
    resetCustomerTypeForm() {
      this.invoiceForm.customer.address = null;
      this.invoiceForm.customer.state = null;
      this.invoiceForm.customer.city = null;
      this.invoiceForm.customer.zip = null;
      this.invoiceForm.customer.is_tax_resale_number = false;
      this.invoiceForm.customer.tax_resale_number = null;
    },
    resetShippingForm() {
      this.invoiceForm.customer.ship_customer_name = null;
      this.invoiceForm.customer.ship_customer_phone = '';
      this.invoiceForm.customer.ship_address = null;
      this.invoiceForm.customer.ship_city = null;
      this.invoiceForm.customer.ship_state = null;
      this.invoiceForm.customer.ship_zip = null;
    },
    customerInformation() {
      var info = '<br>';
      if (this.invoiceForm.customer.customer_name) info += this.invoiceForm.customer.customer_name;
      if (this.invoiceForm.customer_type != 'customer') {
        if (this.invoiceForm.customer.business_name) info += this.invoiceForm.customer.business_name;
        if (this.invoiceForm.customer.address) info += '<br>' + this.invoiceForm.customer.address;
        if (this.invoiceForm.customer.state) info += ', ' + this.invoiceForm.customer.state;
        if (this.invoiceForm.customer.city || this.invoiceForm.customer.zip) info += `<br>${this.invoiceForm.customer.city}, ${this.invoiceForm.customer.zip}`;
        if (this.invoiceForm.customer.business_phone) info += '<br>B. T.: ' + this.invoiceForm.customer.business_phone;
      }
      if (this.invoiceForm.customer.customer_phone) info += '<br>C. T.: ' + this.invoiceForm.customer.customer_phone;
      return info;
    },
    shippingInformation() {
      var info = '<br>';
      if (this.invoiceForm.customer.ship_is_shipping_required) {
        if (this.invoiceForm.customer.ship_customer_name) info += this.invoiceForm.customer.ship_customer_name;
        if (this.invoiceForm.customer.ship_address) info += '<br>' + this.invoiceForm.customer.ship_address;
        if (this.invoiceForm.customer.ship_state) info += ', ' + this.invoiceForm.customer.ship_state;
        if (this.invoiceForm.customer.ship_city || this.invoiceForm.customer.ship_zip) info += `<br>${this.invoiceForm.customer.ship_city}, ${this.invoiceForm.customer.ship_zip}`;
        if (this.invoiceForm.customer.ship_customer_phone) info += '<br>T: ' + this.invoiceForm.customer.ship_customer_phone;
        if (this.invoiceForm.customer.ship_note) info += `<br>${this.invoiceForm.customer.ship_note}`;
      } else {
        info += 'NONE';
      }
      return info;
    },
    // getCustomersSearchText(item) {
    //   let business_name = item.business_name ? item.business_name+', ' : '';
    //   let business_phone = item.business_phone ? item.business_phone+', ' : '';
    //   let customer_name = item.customer_name ? item.customer_name+', ' : '';
    //   let customer_phone = item.customer_phone ? item.customer_phone+', ' : '';
    //   return `${business_name} ${business_phone} ${customer_name} ${customer_phone}`;
    // },
    onCustomerAddressSelect(data) {
      this.invoiceForm.customer = {
        ...this.invoiceForm.customer,
        ...data,
      };
      delete this.invoiceForm.customer.valid;
    },
    onShippingAddressSelect(data) {
      this.invoiceForm.customer = {
        ...this.invoiceForm.customer,
        ship_address: data.address,
        ship_city: data.city,
        ship_state: data.state,
        ship_zip: data.zip,
      };
      delete this.invoiceForm.customer.valid;
    },
    businessPhoneCountryChanged(country) {
      this.invoiceForm.customer.business_phone_country_code = country.dialCode
    },
    customerPhoneCountryChanged(country) {
      this.invoiceForm.customer.customer_phone_country_code = country.dialCode
    },
    shippingPhoneCountryChanged(country) {
      this.invoiceForm.customer.ship_customer_phone_country_code = country.dialCode
    },
    getUsFormatNumber(value) {
      if (!value) return "";
      const formattedValue = value
        .toString()
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !formattedValue[2]
        ? formattedValue[1]
        : "(" +
        formattedValue[1] +
        ") " +
        formattedValue[2] +
        (formattedValue[3] ? "-" + formattedValue[3] : "");
    },
    onSelectBusiness(value) {
      try {
        if (value && typeof value != 'string') {
          this.invoiceForm.customer.business_name = value.business_name ? value.business_name : '';
          this.invoiceForm.customer.business_phone = value.business_phone ? this.getUsFormatNumber(value.business_phone) : '';
          this.invoiceForm.customer.customer_phone = value.customer_phone ? this.getUsFormatNumber(value.customer_phone) : '';
          this.invoiceForm.customer.city = value.city;
          this.invoiceForm.customer.bd_customer_name = value.customer_name ? value.customer_name : '';
          this.invoiceForm.customer.address = value.address;
          this.invoiceForm.customer.state = value.state;
          this.invoiceForm.customer.is_tax_resale_number = value.is_tax_resale_number ? true : false;
          this.invoiceForm.customer.tax_resale_number = value.tax_resale_number;
          this.invoiceForm.customer.zip = value.zip;
        }
        this.queryBusinessTerm = null;
        this.customerSuggestions = [];
      } catch (e) {
        console.log("eeee: ", e);
      }
    },
    onSelectCustomers(value) {
      try {
        if (value && typeof value != 'string') {
          this.invoiceForm.customer.customer_name = value.customer_name ? value.customer_name : '';
          this.invoiceForm.customer.customer_phone = value.customer_phone ? this.getUsFormatNumber(value.customer_phone) : '';
          this.invoiceForm.customer.is_tax_resale_number = value.is_tax_resale_number ? true : false;
          this.invoiceForm.customer.tax_resale_number = value.tax_resale_number;
        }
        this.queryTerm = null;
        this.customerSuggestions = [];
      } catch (e) {
        console.log("eeee: ", e);
      }
    },
    async fetchCustomersList(searchText) {
      this.customersSearchLoading = true;
      try {
        if (searchText != null && searchText.length >= 4) {
          this.customerSuggestions = [];
          await this.getCustomersList({
            search: searchText,
            type: this.invoiceForm.customer_type
          });

          this.customerSuggestions = this.customersList.map(function (val) {
            let business_name = val.business_name ? val.business_name + ', ' : '';
            let business_phone = val.business_phone ? val.business_phone + ', ' : '';
            let customer_name = val.customer_name ? val.customer_name + ', ' : '';
            let customer_phone = val.customer_phone ? val.customer_phone + ', ' : '';

            return {
              ...val,
              lable: `${business_name}${business_phone}${customer_name}${customer_phone}`
            };
          });

          console.log("this.customerSuggestions: ", this.customerSuggestions);
        } else if (this.queryTerm == null || this.queryTerm.length == 0) {
          this.customerSuggestions = [];
        }
      } catch ({ message }) {
        console.error(message);
        this.apiErrorMessage = message;
      } finally {
        this.customersSearchLoading = false;
      }
    },
    calculateAmount() {
      if (this.invoiceForm.work_order == 1) {
        if (this.invoiceForm.payment.budget_tax_into_customers_sales_price) {
          if (this.salesTaxRate.sales_tax_rate != 0) {
            this.invoiceForm.payment.sales_tax_amount = (parseFloat(this.invoiceForm.payment.itemtotal) * parseFloat(this.salesTaxRate.sales_tax_rate)) / 100;
          } else {
            this.invoiceForm.payment.sales_tax_amount = null;
          }
          this.invoiceForm.payment.discount_amount = this.invoiceForm.payment.sales_tax_amount;
          this.invoiceForm.payment.subtotal = parseFloat(this.invoiceForm.payment.itemtotal) - parseFloat(this.invoiceForm.payment.discount_amount ?? 0);
          this.invoiceForm.payment.discount = parseFloat((this.invoiceForm.payment.discount_amount * 100) / this.invoiceForm.payment.subtotal);
        } else {
          if (this.invoiceForm.payment.discount != 0) {
            this.invoiceForm.payment.discount_amount = (parseFloat(this.invoiceForm.payment.itemtotal) * parseFloat(this.invoiceForm.payment.discount)) / 100;
          } else {
            this.invoiceForm.payment.discount_amount = null;
          }
          this.invoiceForm.payment.subtotal = parseFloat(this.invoiceForm.payment.itemtotal) - parseFloat(this.invoiceForm.payment.discount_amount ?? 0);
          if (this.salesTaxRate.sales_tax_rate != 0) {
            this.invoiceForm.payment.sales_tax_amount = (this.invoiceForm.payment.subtotal * parseFloat(this.salesTaxRate.sales_tax_rate)) / 100;
          } else {
            this.invoiceForm.payment.sales_tax_amount = null;
          }
        }
      } else {
        this.invoiceForm.payment.subtotal = this.invoiceForm.payment.itemtotal;
        this.invoiceForm.payment.discount_amount = null;
        this.invoiceForm.payment.sales_tax_amount = null;
        this.invoiceForm.payment.shipping_charges = null;
      }
    },
    getTotalAmount() {
      if (this.invoiceForm.work_order == 1) {
        return (parseFloat(this.invoiceForm.payment.itemtotal) + parseFloat(this.invoiceForm.payment.sales_tax_amount ?? 0) + parseFloat(this.invoiceForm.payment.shipping_charges ?? 0)) - parseFloat(this.invoiceForm.payment.discount_amount ?? 0);
      } else {
        return this.invoiceForm.payment.itemtotal;
      }
    },
    stepContinue(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          console.log("this.e1: ", this.e1);
          if (this.e1 === 5) {
            this.conformDailog = true;
          } else {
            if (this.e1 == 0) {
              this.e1 = this.e1 + 2;
              this.scrollup();
            } else {
              this.e1++;
              this.scrollup();
            }
          }

        }
      });
    },
    step2ButtonValidation() {
      // this.vErrors.clear()
      if (this.invoiceForm.customer_type == 'customer') {
        if (!this.invoiceForm.customer.customer_name || !this.invoiceForm.customer.customer_phone) {
          return true;
        } else {
          return false;
        }
      } else if (this.invoiceForm.customer_type == 'body_shop' || this.invoiceForm.customer_type == 'dismantler') {
        if (!this.invoiceForm.customer.business_name || !this.invoiceForm.customer.business_phone) {
          return true;
        } else {
          return false;
        }
      }
    },
    step3ButtonValidation() {
      // this.vErrors.clear()
      if (this.invoiceForm.customer.ship_is_shipping_required && !this.invoiceForm.customer.ship_address) {
        return true;
      } else {
        return false;
      }
    },
    async saveInvoiceData() {
      try {
        this.saving = true;
        this.isSaving = true;
        var data = _.cloneDeep(this.invoiceForm);
        if (data.customer.business_phone) {
          data.customer.business_phone = new PhoneNumber(data.customer.business_phone, 'SE').getNumber('significant');
        }
        if (data.customer.customer_phone) {
          data.customer.customer_phone = new PhoneNumber(data.customer.customer_phone, 'SE').getNumber('significant');
        }
        if (data.customer.ship_customer_phone) {
          data.customer.ship_customer_phone = new PhoneNumber(data.customer.ship_customer_phone, 'SE').getNumber('significant');
        }

        data = {
          ...data,
          ...data.payment
        };
        delete data.payment;

        data.discount_amount = data.discount_amount ?? 0;
        data.sales_tax_amount = data.sales_tax_amount ?? 0;
        data.shipping_charges = data.shipping_charges ?? 0;
        data.deposit_amount = data.deposit_amount ?? 0;

        if (data.payment_term == 'full_charge') {
          data.deposit_amount = (parseFloat(data.itemtotal) + parseFloat(data.sales_tax_amount) + parseFloat(data.shipping_charges)) - parseFloat(data.discount_amount);
        }

        if (data.work_order == 2) {
          data.status = 2;
        } else if (data.payment_term == 'full_charge' && data.down_payment == false) {
          data.status = data.work_order;
        } else if (data.payment_term != 'full_charge' && data.down_payment == false) {
          data.status = 5;
        } else if (data.deposit_amount != (parseFloat(data.itemtotal) + parseFloat(data.sales_tax_amount) + parseFloat(data.shipping_charges)) - parseFloat(data.discount_amount)) {
          data.status = 3;
        }

        delete data.work_order;

        if (!this.is_tax_resale_number) {
          data.tax_resale_number = null;
        }

        if (data.customer_type == 'customer') {
          data.customer.business_name = null;
          data.customer.business_phone = null;
          data.customer.address = null;
          data.customer.state = null;
          data.customer.city = null;
          data.customer.zip = null;
        } else {
          data.customer.customer_name = null;
          data.customer.customer_phone = null;

          // data.customer.customer_name = data.customer.bd_customer_name;
          delete data.customer.bd_customer_name;
        }

        data.subtotal = this.getTotalAmount();

        await this.saveInvoice(data);

        this.$router.push('/dashboard/billing-invoice/dashboard');
      } catch ({ message }) {
        this.apiErrorMessage = message ? message : 'Something went wrong to add invoice!';
        this.snackbarError = true;
      } finally {
        this.saving = false;
        this.isSaving = false;
      }
    },
    goBack() {
      this.e1--
      document.getElementById('scrolling_div').scrollTop = 0;
    },
    scrollup() {
      document.getElementById('scrolling_div').scrollTop = 0;
    }
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}

.v-icon.v-icon::after {
  background-color: unset;
}
</style>